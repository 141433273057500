import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { CookieService } from "ngx-cookie-service";
import { RedirectRequest } from "@azure/msal-browser";
import { Observable, filter } from "rxjs";

export class OAuth2RequestProto {
  username: string;
  password: string;
  scope: string = "write";
  grant_type: string = "password";
  realm: string;
  authType: string;
  refresh_token: string;
}

@Injectable()
export class OAuth2Service {
  constructor(
    private cookieService: CookieService,
    private authService: MsalService
  ) {}

  public storeAccessTokenResponse(
    access_token: string,
    refresh_token?: string
  ): void {
    this.cookieService.set("fgsacthfdsg", access_token);
    this.cookieService.set("gdsrefshdsf", refresh_token);
  }

  public isAuthenticated(): boolean {
    if (this.cookieService.get("fgsacthfdsg")) {
      return true;
    }
    return false;
  }

  public getAccessToken(): string {
    return this.cookieService.get("fgsacthfdsg");
  }

  public refreshToken(): Observable<any> {
    return new Observable((observe) => {
      const msalRequestSilent: RedirectRequest = {
        scopes: [
          "profile",
          "openid",
          "email",
          "9183bedc-549b-4fb7-9623-b49f6cef2136/.default",
          "offline_access",
        ],
      };
      this.authService.acquireTokenSilent(msalRequestSilent).subscribe(
        (response) => {
          console.log("msalRequestSilent", response);
          observe.next(response);
        },
        (error) => {
          console.log(error);
          this.cookieService.deleteAll();
          this.authService.logout();
        }
      );
    });
  }
}
