import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WebServiceService } from "./web-service.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductListService {
  constructor(
    private http: HttpClient,
    private webService: WebServiceService
  ) {}

  onActivateInactivateUser(url): Observable<any> {
    return new Observable((observe) => {
      this.webService.put(url).subscribe(
        (response) => {
          observe.next(response);
        },
        (error) => {
          observe.next(error);
          observe.complete();
        }
      );
    });
  }

  onActivateInactivateProductSKU(url, data): Observable<any> {
    return new Observable((observe) => {
      this.webService.put(url, data, environment.productsServerUrl).subscribe(
        (response) => {
          observe.next(response);
        },
        (error) => {
          observe.next(error);
          observe.complete();
        }
      );
    });
  }

  getProductManagers(url): Observable<any> {
    return new Observable((observe) => {
      this.webService.get(url).subscribe(
        (response) => {
          observe.next(response);
        },
        (error) => {
          observe.next(error);
          observe.complete();
        }
      );
    });
  }

  downloadPdf(url): Observable<any> {
    return new Observable((observe) => {
      this.webService.downloadPdf(url, environment.potServerurl).subscribe(
        (response) => {
          observe.next(response);
        },
        (error) => {
          observe.next(error);
          observe.complete();
        }
      );
    });
  }

  deletePOT(url): Observable<any> {
    return new Observable((observe) => {
      this.webService.delete(url, environment.potServerurl).subscribe(
        (response) => {
          observe.next(response);
        },
        (error) => {
          observe.next(error);
          observe.complete();
        }
      );
    });
  }
}
