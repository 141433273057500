import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layouts/layout.component";
import { BrowserUtils } from "@azure/msal-browser";
import { CustomResolver } from "./shared/services/custom.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/pot",
    pathMatch: "full",
    resolve: { message: CustomResolver },
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./layouts/layout.module").then((m) => m.LayoutModule),
      },
    ],
    resolve: { message: CustomResolver },
  },
  {
    path: "error",
    loadChildren: () =>
      import("app/error-pages/error-pages.module").then(
        (m) => m.ErrorPagesModule
      ),
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled",
    }),
  ],
  providers: [CustomResolver],
})
export class AppRoutingModule {}
