export const SalesMenus = [
  {
    path: "/pot",
    title: "Product Order Table List",
    menuTitle: "POTs",
    icon: "/assets/svg/pot_icon.svg",
    class: "icon-pot",
  },
  {
    path: "/user/notification",
    title: "Notifications",
    menuTitle: "Notifications",
    icon: "/assets/svg/noti_icon.svg ",
    class: "icon-notifications",
  },
];

export const PMMenus = [
  {
    path: "/pot",
    title: "Product Order Table List",
    menuTitle: "POTs",
    icon: "/assets/svg/pot_icon.svg",
    class: "icon-pot",
  },
  {
    path: "/product",
    title: "Product List",
    menuTitle: "Products",
    icon: "/assets/svg/products_icon.svg ",
    class: "icon-product",
  },
  {
    path: "/user/notification",
    title: "Notifications",
    menuTitle: "Notifications",
    icon: "/assets/svg/noti_icon.svg ",
    class: "icon-notifications",
  },
];

export const AdminMenus = [
  {
    path: "/pot",
    title: "Product Order Table List",
    menuTitle: "POTs",
    icon: "/assets/svg/pot_icon.svg",
    class: "icon-pot",
  },
  {
    path: "/product",
    title: "Product List",
    menuTitle: "Products",
    icon: "/assets/svg/products_icon.svg",
    class: "icon-product",
  },
  {
    path: "/user/list",
    title: "User List",
    menuTitle: "Users",
    icon: "/assets/svg/users_icon.svg",
    class: "icon-users",
  },
  {
    path: "/user/notification",
    title: "Notifications",
    menuTitle: "Notifications",
    icon: "/assets/svg/noti_icon.svg",
    class: "icon-notifications",
  },
];

export class GetNotificationObj {
  limit: number = 5;
}
