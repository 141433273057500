import { ModuleWithProviders, NgModule } from "@angular/core";
import { LoaderService } from "./loader.service";
import { LoaderComponent } from "./loader.component";
import { CommonModule } from "@angular/common";
import { LottieModule } from "ngx-lottie";

export function playerFactory() {
  return import("lottie-web");
}
@NgModule({
  imports: [CommonModule, LottieModule.forRoot({ player: playerFactory })],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders<LoaderModule> {
    return {
      ngModule: LoaderModule,
      providers: [LoaderService],
    };
  }
}
