import { Injectable } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class AppUtilsService {
  constructor() {}

  public getDialogData(data?: any, disableClose = false): MatDialogConfig {
    const config = new MatDialogConfig();
    config.panelClass = "mat-dialog-mini-content";
    config.closeOnNavigation = true;
    config.hasBackdrop = true;
    config.disableClose = disableClose;
    if (data) {
      config.data = data;
    }
    return config;
  }

  public findObject(list, attr, matchedValue): any {
    return list.find((resultb) => resultb[attr] === matchedValue.toString());
  }
  public findObjectForNumber(list, attr, matchedValue): any {
    return list.find((resultb) => resultb[attr] === matchedValue);
  }

  public getIndex(list: any[], key: string, data: any): any {
    return list
      .map((d: any) => {
        return d[key];
      })
      .indexOf(data);
  }

  public sortList(list: any[], attr?: string): any[] {
    return list.sort((a, b) => {
      let objectOne = a,
        objectTwo = b;
      if (attr) {
        objectOne = a[attr];
        objectTwo = b[attr];
      }
      if (typeof objectOne === "string") {
        objectOne = objectOne.toLowerCase();
      }
      if (typeof objectTwo === "string") {
        objectTwo = objectTwo.toLowerCase();
      }
      if (objectOne < objectTwo) {
        return -1;
      } else if (objectOne > objectTwo) {
        return 1;
      }

      return 0;
    });
  }

  sortFunction(dataArray, keyToSort, orderToSort) {
    if (dataArray && dataArray.length > 0) {
      if (orderToSort === "ASC") {
        dataArray.sort((a, b) => {
          const nameA = a[keyToSort];
          const nameB = b[keyToSort];
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else if (orderToSort === "DEC") {
        dataArray.sort((a, b) => {
          const nameA = a[keyToSort];
          const nameB = b[keyToSort];
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
      }
    }
    return dataArray;
  }
}
