<nav class="navbar navbar-expand-lg  navbar-absolute fixed-top p-0">
    <div class="container-fluid p-0">
        <div class="collapse navbar-collapse" id="navigation">
            <div class="pr-5 mr-3">
                <a href="javascript:void(0)">
                    <div class="logo-img">
                        <img src="/assets/img/logo.png" [routerLink]="['/pot']" />
                    </div>
                </a>
            </div>
            <div class="mt-4 nav-title pl-5 pt-2">
                {{getTitle()}}
                <app-ng-dynamic-breadcrumb [bgColor]="breadcrumbConfig['bgColor']"
                    [fontColor]="breadcrumbConfig['fontColor']" [fontSize]="breadcrumbConfig['fontSize']"
                    [lastLinkColor]="breadcrumbConfig['lastLinkColor']"></app-ng-dynamic-breadcrumb>
            </div>
            <div class="ml-auto icon-padding">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown mt-2 pt-1">
                        <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <img src="/assets/svg/bell_ring_notification_alarm.svg">
                            <span *ngIf="notificationCount > 0" class="notification">{{notificationCount}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <div class="row dropdown-item noti-title">
                                <div class="col-12 padding-left-10">NOTIFICATIONS</div>
                            </div>
                            <ng-container *ngFor="let content of notificationList; let last=last">
                                <div class="row flex-wrap dropdown-item " href="javascript:void(0)"
                                    (click)="$event.stopPropagation()">
                                    <div class="col-12 padding-left-10" style="white-space: break-spaces !important;;">
                                        {{content?.webText}}</div>
                                    <div class="col-12 padding-left-10">
                                        <span class="text-span">
                                            {{content.sentOn | date:'short'}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="row flex-wrap dropdown-item "
                                *ngIf="notificationList && notificationList?.length == 0">
                                <div class="col-10"
                                    style="white-space: break-spaces !important;padding-left: 5px !important;">
                                    No records found</div>
                            </div>
                            <div class="d-flex justify-content-end"
                                *ngIf="notificationList && notificationList?.length > 0">
                                <div>
                                    <button *ngIf="notificationCount > 0" type="button" mat-button color="primary" [autofocus]="false"
                                        (click)="clearNotifications()" class="verify-button pr-0">
                                        MARK AS READ
                                    </button>
                                    <button type="button" mat-button color="primary" [autofocus]="false"
                                        (click)="viewAllNoti()" class="verify-button pr-0">
                                        VIEW ALL
                                        <i class="material-icons">navigate_next</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pr-4" href="javascript:void(0)" id="profileDropdown" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <div class="profile">
                                <span>
                                    {{userShortName}}
                                </span>
                            </div>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right dropdown-profile"
                            aria-labelledby="profileDropdown">
                            <a class="dropdown-item pl-3" href="javascript:void(0)">

                                <div class="row">
                                    <div class="col-4">
                                        <div class="profile">
                                            <span>
                                                {{userShortName}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-8 pt-1 pl-1"> <ng-container> <span> {{userId}}</span></ng-container>
                                        <br />
                                        <ng-container class="text-span">{{userRole}}</ng-container>
                                    </div>
                                </div>

                            </a>
                            <a class="dropdown-item border-top pl-4" href="javascript:void(0)" (click)="onLogout()">
                                <div class="row">
                                    <div class="col-5">
                                        <img src="assets/svg/sign_out_icon.svg" width="24" height="24">
                                    </div>
                                    <div class="col-7">
                                        <div> <span class="pl-2">Sign Out</span></div>
                                    </div>
                                </div>


                            </a>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</nav>