<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="nav-item" [navigationDelay]="1000">
            <a class="nav-link" [routerLink]="[menuItem.path]" (click)="menuClick(menuItem)">
                <div class="sidebar-icons" [ngClass]="menuItem.class">
                    <p>{{menuItem.menuTitle}}</p>
                </div>
            </a>
        </li>
    </ul>
</div>