import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AlertDialogComponent } from "./components/alert-dialog.component";
import { WebServiceService } from "./services/web-service.service";
import { CommonListComponent } from "./components/common-list/common-list.component";
import { ProductListService } from "./services/product-list.service";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { AppUtilsService } from "./services/app-utils.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatSortModule } from "@angular/material/sort";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectAllComponent } from "./components/mat-select-all/mat-select-all.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UserService } from "./services/user.service";
import { ToasterService } from "./services/toaster.service";
import { OAuth2Service } from "./services/o-auth2.service";
import { FilterPipe } from "./pipe/filter.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IsAuthenticatedGuard } from "./guard/is-authenticated.guard";
import { IsProductAuthenticatedGuard } from "./guard/product.guard";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { LoadingMessageComponent } from "./components/loading-message/loading-message.component";

@NgModule({
  declarations: [
    AlertDialogComponent,
    CommonListComponent,
    MatSelectAllComponent,
    ConfirmDialogComponent,
    FilterPipe,
    LoadingMessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSortModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgDynamicBreadcrumbModule,
    MatTooltipModule,
  ],
  providers: [
    WebServiceService,
    OAuth2Service,
    ProductListService,
    AppUtilsService,
    UserService,
    ToasterService,
    IsAuthenticatedGuard,
    IsProductAuthenticatedGuard,
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    AlertDialogComponent,
    CommonListComponent,
    MatSelectAllComponent,
    FilterPipe,
    MatTooltipModule,
    NgDynamicBreadcrumbModule,
    LoadingMessageComponent,
    MatTooltipModule,
  ],
  entryComponents: [AlertDialogComponent, LoadingMessageComponent],
})
export class SharedModule {}
