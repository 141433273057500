import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./layouts/layout.component";
import { LoaderModule } from "./components/loader/loader.module";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { OAuth2Service } from "./shared/services/o-auth2.service";
import { SharedModule } from "./shared/shared.module";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "./shared/services/user.service";
import { WebServiceService } from "./shared/services/web-service.service";
import { environment } from "environments/environment";
const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority:
        "https://login.microsoftonline.com/1bf6dfc7-6a14-4d90-bca6-7347ebcb6b3e",
      redirectUri: environment.serverUILink,
      postLogoutRedirectUri: environment.serverUILink,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set("https://graph.microsoft-ppe.com/v1.0/me", [
    "user.read",
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        "profile",
        "openid",
        "email",
        environment.scopeId,
        "offline_access",
      ],
    },
    loginFailedRoute: "/login-failed",
  };
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ComponentsModule,
    LoaderModule,
    RouterModule,
    AppRoutingModule,
    MsalModule,
    SharedModule,
  ],
  declarations: [AppComponent, LayoutComponent],
  providers: [
    OAuth2Service,
    CookieService,
    UserService,
    WebServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
