import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject, timer } from "rxjs";
import { OAuth2Service } from "./o-auth2.service";
import { ToasterService } from "./toaster.service";
import { LoaderService } from "app/components/loader/loader.service";

@Injectable({
  providedIn: "root",
})
export class WebServiceService {
  public unAuthorizedError = false;
  public fileUploadProgress: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private oAuthService: OAuth2Service,
    private toaster: ToasterService,
    private loader: LoaderService
  ) {}

  public getTokenDetails(): string {
    return this.oAuthService.getAccessToken();
  }

  private checkToken(): Promise<any> {
    return new Promise((resolve) => {
      let count = 0;
      const timerSub = timer(1000, 1000);
      const subscription = timerSub.subscribe((time) => {
        count++;
        if (count > 5) {
          subscription.unsubscribe();
          resolve(true);
        }
        if (this.oAuthService.getAccessToken()) {
          subscription.unsubscribe();
          resolve(true);
        }
      });
    });
  }

  private getFullUrl(url: string, backendUrl?: string): string {
    if (backendUrl) {
      return backendUrl + url;
    }
    return environment.serverUrl + url;
  }

  private getRequestOptions(
    params?: any,
    body?: any,
    responseType?: string
  ): any {
    const httpOptions: any = {};

    /*set header section*/
    const httpHeader = {};
    httpHeader["Authorization"] = "Bearer " + this.getTokenDetails();

    httpOptions["headers"] = new HttpHeaders(httpHeader);

    /*Set param section*/
    if (params) {
      httpOptions["params"] = new HttpParams({ fromObject: params });
    }

    /*Set param section*/
    if (body) {
      httpOptions["body"] = body;
    }
    if (responseType) {
      httpOptions["responseType"] = responseType;
    }
    return httpOptions;
  }

  private handleError(error: HttpErrorResponse | any): Observable<string> {
    return new Observable((resolve) => {
      if (error.status === 401) {
        this.oAuthService.refreshToken().subscribe(
          (response) => {
            console.log(response);
            this.oAuthService.storeAccessTokenResponse(response.accessToken);
            resolve.next("success");
          },
          (tokenError) => {
            resolve.next("error");
          }
        );
      } else {
        resolve.next("error");
      }
    });
  }

  public get(endPoint: string, backendUrl?: string): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .get(this.getFullUrl(endPoint, backendUrl), this.getRequestOptions())
          .subscribe(
            (response) => resolve.next(response),
            (httpError) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  public getWithParams(
    endPoint: string,
    params: any,
    backendUrl?: string
  ): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .get(
            this.getFullUrl(endPoint, backendUrl),
            this.getRequestOptions(params)
          )
          .subscribe(
            (response) => resolve.next(response),
            (httpError) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  public post(
    endPoint: string,
    data: any,
    backendUrl?: string
  ): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .post(
            this.getFullUrl(endPoint, backendUrl),
            data,
            this.getRequestOptions()
          )
          .subscribe(
            (response) => resolve.next(response),
            (httpError: HttpErrorResponse) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  public put(
    endPoint: string,
    data?: any,
    backendUrl?: string
  ): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .put(
            this.getFullUrl(endPoint, backendUrl),
            data,
            this.getRequestOptions()
          )
          .subscribe(
            (response) => resolve.next(response),
            (httpError: HttpErrorResponse) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  public putWithUrlData(
    endPoint: string,
    data?: any,
    backendUrl?: string
  ): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .put(
            this.getFullUrl(endPoint, backendUrl),
            data,
            this.getRequestOptions()
          )
          .subscribe(
            (response) => resolve.next(response),
            (httpError: HttpErrorResponse) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  public downloadPdf(endPoint: string, backendUrl?: string): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .get(this.getFullUrl(endPoint, backendUrl), this.getRequestOptions())
          .subscribe(
            (response) => resolve.next(response),
            (httpError: HttpErrorResponse) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }

  multipartFileUploadForPUT(
    endPoint: string,
    formData: FormData,
    backendUrl?: string
  ): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        let xhr: XMLHttpRequest;

        const requestOptions = this.getRequestOptions();
        return Observable.create((observer: any) => {
          xhr = new XMLHttpRequest();
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                observer.next(JSON.parse(xhr.response));
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          };
          xhr.upload.onprogress = (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            this.fileUploadProgress.next(progress);
          };
          xhr.open("PUT", this.getFullUrl(endPoint, backendUrl), true);
          xhr.setRequestHeader(
            "Authorization",
            requestOptions.headers.get("Authorization")
          );
          xhr.send(formData);
        }).subscribe(
          (response) => resolve.next(response),
          (httpError: HttpErrorResponse) => {
            console.log(httpError);
            count++;
            this.handleError(httpError).subscribe((handleError) => {
              if (handleError === "success") {
                if (count >= 3) {
                  resolve.error(httpError);
                } else {
                  takeCallback();
                }
              } else if (handleError === "error") {
                if (httpError.status === 0) {
                  this.loader.hide();
                  this.toaster.showToast(
                    "There is no internet connection. Please try after sometime.",
                    "error"
                  );
                }
                resolve.error(httpError);
              }
            });
          }
        );
      };

      takeCallback();
    });
  }

  public delete(endPoint: string, backendUrl?: string): Observable<any> {
    let count = 0;
    return new Observable((resolve) => {
      const takeCallback = (): void => {
        this.http
          .delete(
            this.getFullUrl(endPoint, backendUrl),
            this.getRequestOptions()
          )
          .subscribe(
            (response) => resolve.next(response),
            (httpError) => {
              console.log(httpError);
              count++;
              this.handleError(httpError).subscribe((handleError) => {
                if (handleError === "success") {
                  if (count >= 3) {
                    resolve.error(httpError);
                  } else {
                    takeCallback();
                  }
                } else if (handleError === "error") {
                  if (httpError.status === 0) {
                    this.loader.hide();
                    this.toaster.showToast(
                      "There is no internet connection. Please try after sometime.",
                      "error"
                    );
                  }
                  resolve.error(httpError);
                }
              });
            }
          );
      };

      takeCallback();
    });
  }
}
