import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoaderService } from "./loader.service";
import { Subscription } from "rxjs";
import * as $ from "jquery";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

@Component({
  selector: "inner-loader",
  template: `
    <div *ngIf="showLoader">
      <div class="d-flex justify-content-center">
        <div class="text-primary-color" role="status" id="spinner">
          <ng-lottie [options]="options" width="200px"></ng-lottie>
        </div>
      </div>
    </div>
  `,
  styleUrls: ["./loader.scss"],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loaderSubscription: Subscription;
  showLoader: boolean = false;
  options: AnimationOptions = {
    path: "/assets/loader.json",
  };

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderSubscription = this.loaderService.loader.subscribe(
      (value: boolean) => {
        if (value) {
          this.showLoader = value;
          this.options = {
            path: "/assets/loader.json",
          };
        } else {
          this.showLoader = value;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }
}
