<div class="wrapper">
    <div class="header-panel">
        <app-navbar></app-navbar>
    </div>
    <div class="sidebar" data-color="primary" data-background-color="white">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <router-outlet></router-outlet>
        <inner-loader></inner-loader>
    </div>
</div>