import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, ReplaySubject } from "rxjs";

export class UserProfilePojo {
  public token?: string;
  public userId: string;
  public userRole: string;
  public userName: string;
  public isAdmin: boolean;
  public isPM: boolean;
  public isSalesManager: boolean;
  public notificationCount: number;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  public Observable: ReplaySubject<UserProfilePojo> = new ReplaySubject();
  private userData: UserProfilePojo = new UserProfilePojo();
  public notificationCount: ReplaySubject<number> = new ReplaySubject();
  constructor(public cookieService: CookieService) {}

  setUser(data: UserProfilePojo): void {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      this.userData[key] = data[key];
    });
    this.Observable.next(this.userData);
    this.cookieService.set("sjgnei", JSON.stringify(this.userData));
  }

  getObserver(): Observable<UserProfilePojo> {
    return this.Observable;
  }

  getUser(): UserProfilePojo {
    return JSON.parse(this.cookieService.get("sjgnei"));
  }

  setNotificationCount(count): void {
    this.notificationCount.next(count);
  }

  getNotificationCount(): Observable<number> {
    return this.notificationCount;
  }

  setUserProperty(key: string, value: any): void {
    this.userData[key] = value;
    this.Observable.next(this.userData);
    this.cookieService.set("sjgnei", JSON.stringify(this.userData));
  }

  getUserProperty(key: string): any {
    this.userData = JSON.parse(this.cookieService.get("sjgnei"));
    return this.userData[key];
  }

  resetUser(): void {
    this.userData = new UserProfilePojo();
    this.cookieService.set("sjgnei", JSON.stringify(this.userData));
  }

  public setAccessByRole(userRole) {
    if (userRole === "Admin") {
      this.setUserProperty("isAdmin", true);
    } else if (userRole === "Product Manager") {
      this.setUserProperty("isPM", true);
    } else if (userRole === "Sales Representative") {
      this.setUserProperty("isSalesManager", true);
    }
  }
}
