import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Inject } from "@angular/core";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { UserService } from "./shared/services/user.service";
import { OAuth2Service } from "./shared/services/o-auth2.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
    private http: HttpClient,
    private OAuthService: OAuth2Service,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    console.log("app component 3");
    if (this.cookieService.get("sjgnei")) {
      this.userService.setUser(JSON.parse(this.cookieService.get("sjgnei")));
    } else {
      this.setAllRoleFalse();
    }
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log("Event subscribed");
        const payload = result.payload as AuthenticationResult;
        console.log(payload);
        this.OAuthService.storeAccessTokenResponse(payload.accessToken);
        this.authService.instance.setActiveAccount(payload.account);
        const userObj = this.authService.instance.getActiveAccount();
        this.userService.setUserProperty("userId", userObj.username);
        this.userService.setUserProperty("userName", userObj.name);
      });
  }

  setAllRoleFalse() {
    this.userService.setUserProperty("isAdmin", false);
    this.userService.setUserProperty("isPM", false);
    this.userService.setUserProperty("isSalesManager", false);
  }
}
