import { Component, OnInit } from "@angular/core";
import { UserService } from "app/shared/services/user.service";
import { CookieService } from "ngx-cookie-service";
import { AdminMenus, PMMenus, SalesMenus } from "../components.constants";
import { WebServiceService } from "app/shared/services/web-service.service";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];
  userRole: string;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private webService: WebServiceService
  ) {}

  ngOnInit() {
    this.userRole = this.cookieService.get("userRole");
    if (this.userRole === "Product Manager") {
      this.menuItems = PMMenus;
    } else if (this.userRole === "Sales Representative") {
      this.menuItems = SalesMenus;
    } else if (this.userRole === "Admin") {
      this.menuItems = AdminMenus;
    }
  }

  menuClick(menuItem) {
    this.cookieService.delete("fromPOT");
    this.webService
      .getWithParams("/v1/user", {
        email: this.userService.getUserProperty("userId"),
      })
      .subscribe(
        (response) => {
          if (menuItem.path === "/user/notification") {
            this.userService.setNotificationCount(0);
          } else {
            this.userService.setNotificationCount(
              response.userList[0].newNotification
            );
          }
        },
        (error) => {}
      );
  }
}
