import { Injectable } from "@angular/core";
import { Resolve, Router } from "@angular/router";
import { UserService } from "./user.service";
import { CookieService } from "ngx-cookie-service";
import { WebServiceService } from "./web-service.service";
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: "root",
})
export class CustomResolver implements Resolve<any> {
  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
    private webService: WebServiceService,
    private authService: MsalService
  ) {}
  async resolve() {
    console.log("resolver");
    if (this.userService.getUserProperty("userId") === undefined) {
      this.authService.logout();
      return {};
    } else {
      let userResponse = await this.getUserRole();
      if (userResponse !== undefined) {
        const sendData = await this.getUserDetails(userResponse);
        if (!sendData) {
          this.router.navigateByUrl("/error/403");
        } else {
          return sendData;
        }
      }
    }
  }

  getUserRole() {
    return new Promise((resolve) => {
      this.webService
        .getWithParams("/v1/user", {
          username: this.userService.getUserProperty("userId"),
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  getUserDetails(response) {
    if (response.status === 403) {
      return false;
    } else {
      const data = response.userList.filter((obj) => obj.status === "ACTIVE");
      if (data && data.length) {
        const userRole = data[0].role;
        this.cookieService.set("userRole", userRole);
        this.userService.setUserProperty("userRole", userRole);
        if (userRole === "Admin") {
          this.userService.setUserProperty("isAdmin", true);
        } else if (userRole === "Product Manager") {
          this.userService.setUserProperty("isPM", true);
        } else if (userRole === "Sales Representative") {
          this.userService.setUserProperty("isSalesManager", true);
        }
        this.userService.setNotificationCount(data[0].newNotification);
        return this.userService.getUser();
      } else {
        return false;
      }
    }
  }
}
