import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { OAuth2Service } from "../services/o-auth2.service";
import { Observable } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { UserService } from "../services/user.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class IsProductAuthenticatedGuard implements CanActivate, CanLoad {
  constructor(
    private authService: OAuth2Service,
    private router: Router,
    private msalService: MsalService,
    private userService: UserService,
    private cookieService: CookieService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const index = state.url.lastIndexOf(
      route.pathFromRoot[route.pathFromRoot.length - 1].routeConfig.path.split(
        "/"
      )[0]
    );
    const url =
      state.url.substring(0, index) +
      route.pathFromRoot[route.pathFromRoot.length - 1].routeConfig.path;
    if (
      this.authService.isAuthenticated() &&
      (this.userService.getUserProperty("isAdmin") ||
        this.userService.getUserProperty("isPM"))
    ) {
      return true;
    }
    this.router.navigateByUrl("/error/401");
    return false;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.cookieService.deleteAll();
    this.msalService.logout();
    return false;
  }
}
