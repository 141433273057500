import { Component, OnInit, DoCheck } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { UserService } from "app/shared/services/user.service";
import { WebServiceService } from "app/shared/services/web-service.service";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie-service";
import { AdminMenus, GetNotificationObj } from "../components.constants";
import { Location } from "@angular/common";
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public fromPOT: boolean;
  public userShortName: string;
  public userId: string;
  public userName: string;
  public userRole: string;
  public notificationCount: any = 0;
  public notificationList = [];
  public getNotiObj = new GetNotificationObj();
  location: Location;
  private listTitles: any[];
  public breadcrumbConfig: object = {
    bgColor: "#1E1E1E",
    fontSize: "18px",
    fontColor: "#565554",
    lastLinkColor: "#565554",
    symbol: " / ",
  };

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private msalService: MsalService,
    private webService: WebServiceService,
    private router: Router,
    location: Location
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.fromPOT = this.cookieService.get("fromPOT") === "true" ? true : false;
    this.listTitles = AdminMenus.filter((listTitle) => listTitle);
    this.userService.getObserver().subscribe((response) => {
      this.userName = this.userService.getUserProperty("userName");
      this.userId = this.userService.getUserProperty("userId");
      this.userRole = this.cookieService.get("userRole");
      var temp = this.userName?.split(" ").slice(0, 2).join(" ");
      this.userShortName = temp?.match(/\b\w/g).join("");
      this.userService.getNotificationCount().subscribe((count) => {
        this.notificationCount = count;
      });
    });
    this.getAllNotifications();
  }

  resetNotifications(){
    // the unread notifications reset to zero when we request notifications without a limit
    this.webService
      .get(
        "/v1/notification",
        environment.notiServerurl
      ).subscribe();
  }

  getAllNotifications() {
    this.notificationList = [];
    this.webService
      .getWithParams(
        "/v1/notification",
        this.getNotiObj,
        environment.notiServerurl
      )
      .subscribe((response) => {
        this.notificationList = response.notificationList.slice(0, 5);
      });
  }

  ngDoCheck(): void {
    this.fromPOT = this.cookieService.get("fromPOT") === "true" ? true : false;
  }

  onLogout() {
    this.cookieService.deleteAll();
    this.msalService.logout();
  }

  viewAllNoti() {
    this.userService.setNotificationCount(0);
    this.router.navigateByUrl("/user/notification");
  }
  
  clearNotifications() {
    this.userService.setNotificationCount(0);
    this.resetNotifications();
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
  }
}
