export const environment = {
  production: true,
  serverUrl: "https://thhj772po2.execute-api.eu-west-1.amazonaws.com/prod",
  productsServerUrl:
    "https://qqorsf1zda.execute-api.eu-west-1.amazonaws.com/prod",
  potServerurl: "https://wlddxhaze7.execute-api.eu-west-1.amazonaws.com/prod",
  notiServerurl: "https://40g3v3s9oj.execute-api.eu-west-1.amazonaws.com/prod",
  serverUILink: "https://pot.quixant.com/",
  clientId: "ba9a1234-9a69-4bc2-ace3-7479c83918d6",
  scopeId: "ba9a1234-9a69-4bc2-ace3-7479c83918d6/.default",
};
